.cart-item {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-item__price {
    display: flex;
    align-items: center;
}

.cart-item__delete-icon {
    margin-left: 5px;
    cursor: pointer;
}