.basket-wrapper {
    display: flex;
    gap: 40px;
}

.basket-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 100%;
    gap: 20px;
}

.basket-item {
    display: flex;
    align-items: center;
    text-align: center;

    padding-bottom: 20px;
    padding-top: 20px;

    border-top: 1px solid black;
    border-bottom: 1px solid black;

    justify-content: space-between;
}

.basket-item-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.0275em;

    color: #19191d;
}

.basket-price__top {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    display: flex;
    align-items: center;

    color: #070707;
}

.basket-price span {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.022em;

    color: #070707;
}

.basket-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 30px;
}

.basket-info__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;

    color: #070707;
}

.basket-info__price {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;

    display: flex;
    align-items: center;
    text-align: right;

    color: #070707;
}

.basket-info__wrapper {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1120px) {
    .basket-wrapper {
        flex-direction: column;
    }

    .basket-info {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .basket-img {
        display: none;
    }
}
