.fish-table {
  margin-top: 80px;
  width: 100%;
}
.fish-table__tr {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.fish-table tr td {
  padding: 8px;
}
.fish-table__head th {
  padding-bottom: 20px;
}
.fish-table__subtitle {
  font-weight: 500;
}
.fish-table__count {
  width: 15%;
}
.fish-table__tr:hover {
  background-color: rgb(156, 189, 255);
}
