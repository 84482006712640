.header {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 18px 140px 0px;

    gap: 40px;
}

@media (max-width: 1120px) {
    .header {
        padding: 16px 16px 0px;
    }
}
