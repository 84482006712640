.team {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
}

.team h3 {
    margin-bottom: 30px;
}

.team ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 1120px) {
}
