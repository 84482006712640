.navbar-burger {
    height: 50px;
    width: 60px;
    background: white;
    z-index: 50;
    display: flex;
    align-items: center;
    display: none;
}

.burger-btn {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
}

.burger-btn::before {
    content: "";
    position: absolute;
    top: 0;
    width: 20px;
    background-color: #13446d;
    height: 2px;
}

.burger-btn span {
    position: absolute;
    top: 9px;
    width: 20px;
    background-color: #13446d;
    height: 2px;
}

.burger-btn::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 20px;
    background-color: #13446d;
    height: 2px;
}

.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0;
    z-index: 51;

    color: white;

    transform: translateX(-140%);
    transition: all 0.2s;
    
}

.menu.active-burger {
    transform: translateX(0);
}

.blur {
    width: 100vw;
    height: 100vh;
    left: 40%;
    backdrop-filter: blur(2px);
    position: absolute;
}

.menu__content {
    width: 40%;
    height: 100%;
    background-color: #13446d;
    display: flex;
    flex-direction: column;
    padding: 30px;

    transition: all 0.4s;
}

.menu__header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    padding: 12px 0px 40px;
}

.burger-menu-link {
    color: white;
    text-decoration: none;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.burger-menu-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    border-bottom: 1px solid white;
}

.burger-menu-item {
    list-style: none;
}

@media (max-width: 1120px) {
    .navbar-burger {
        display: flex;
    }

}


@media (max-width: 768px) {
    .menu__content {
        width: 100%;
    }

    .blur {
        backdrop-filter: none;
    }
}
