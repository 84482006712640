.hits__section {
    margin-top: 60px;
    padding: 0px 140px;

  
}

.hits__title {
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
}

.hits__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    justify-content: space-between;
}

.button__container {
    position: absolute;
    display: flex;
    right: -0px;
    top: -88px;
}

.button__container > *:not(:last-child) {
    margin-right: 16px;
}

.prev__button,
.next__button {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    background-color: white;
    background-color: white;
    cursor: pointer;
}

.prev__button,
.next__button:hover {
    background-color: var(--blue);
}

.prev__button svg,
.next__button:hover svg {
    fill: var(--blue);
    stroke: white;
}

.prev__button svg,
.next__button svg {
    fill: white;
    stroke: var(--blue);
}

.prev__button svg {
    transform: rotate(180deg);
}

.active {
    background-color: var(--blue);
}

.active svg {
    stroke: white;
}
.active:hover {
    background-color: white;
}

.active:hover svg {
    stroke: var(--blue);
}

.product {
    display: flex;
    flex-direction: column;
    padding: 15px;
    max-width: 560px;
    min-height: 280px;
    box-shadow: 0 8px 24px rgba(198, 211, 241, 0.8);
    border-radius: 6px;
    cursor: pointer;

    filter: blur(3px);
}

.product.promotion {
    width: 100%;
}

.product__img {
    width: 100%;
    max-height: 160px;
}

.product__title {
    font-size: 18px;
    min-height: 38px;
    min-width: 230px;
    margin-top: 12px;
    margin-bottom: 6px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.0275em;
}

.product__price {
    min-width: 200px;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
}

.product__oldprice {
    width: 105px;
    margin-right: 10px;
    text-decoration: line-through;
    color: rgba(89, 89, 89, 0.6);
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
}

.product__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.product:hover {
    box-shadow: 0 20px 30px #c6d3f1;
    transition: 0.2s;
}

.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    min-width: 35px;
    min-height: 35px;
    border-radius: 35px;
    background-color: var(--blue);
    cursor: pointer;
    padding: 10px;
    color: white;
}

.addButton__svg {
    min-width: 17px;
    min-height: 17px;
    stroke: white;
}
.addButton:hover {
    background-color: var(--dark-blue);
    transition: 0.2s;
}

@media (max-width: 1120px) {
    .hits__section {
        padding: 0px 16px;
    }
    .hits__container {
        justify-content: center;
    }
}
