.delivery__section {
    margin-top: 60px;
    padding: 0px 140px;
}

.delivery__title {
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
}

.delivery__container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
}

.prev__button,
.next__button {
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    min-width: 40px;
    min-height: 40px;
    border-radius: 40px;
    background-color: white;
    background-color: white;
    cursor: pointer;
}

.prev__button:hover,
.next__button:hover {
    background-color: var(--blue);
}

.prev__button:hover svg,
.next__button:hover svg {
    fill: var(--blue);
    stroke: white;
}

.prev__button svg,
.next__button svg {
    fill: white;
    stroke: var(--blue);
}

.prev__button svg {
    transform: rotate(180deg);
}

.active {
    background-color: var(--blue);
}

.active:hover {
    background-color: white;
}

.active:hover svg {
    stroke: var(--blue);
}

.active svg {
    stroke: white;
}

@media (max-width: 1120px) {
    .delivery__section {
        padding: 0px 16px;
    }
    .delivery__container {
        justify-content: center;
    }
}
