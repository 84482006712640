.prod-img-box {
    display: flex;
    justify-content: center;
    background: #e1e9fc;
    border-radius: 6px;
    padding: 10px;
    height: 100%;
    max-height: 450px;
}

.prod-img-box img {
    height: fit-content;
    align-self: center;
}

.prod-content {
    width: 100%;
    display: flex;
    gap: 40px;
}

.prod-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.0275em;
    color: rgba(7, 7, 7, 0.8);

    margin-top: 50px;
}

.prod-content__center {
    width: 50%;
}

.prod-content__right {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.prod-content__right h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    color: #13446d;
}

.prod-content__price {
    margin-top: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #070707;
}

.prod-panel {
    display: flex;
    gap: 30px;
    margin: 30px 0 39px;
}

.prod-quantity {
    width: 100px;
    height: 60px;
    border: 1.5px solid #3071b7;
    border-radius: 6px;

    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.prod-quantity button {
    border: none;
    background: none;

    display: flex;

    cursor: pointer;
    padding: 20px 0;
}

.parameters__list {
    display: flex;
    flex-direction: column;

    margin-top: 27px;
    margin-bottom: 20px;
}

.parameters p {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;

    letter-spacing: 0.022em;

    color: #070707;
}

.parameters__item {
    display: flex;
    border-bottom: 1.5px solid #070707;
    padding-bottom: 14px;
    padding-top: 20px;
    justify-content: space-between;

    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;

    color: #070707;
}

@media (max-width: 1120px) {
    .prod-content {
        flex-direction: column;
        gap: 80px;
    }

    .prod-content__center {
        width: 100%;
    }

    .prod-content__right {
        width: 100%;
    }

    main {
        padding: 0 20px;
    }
}
