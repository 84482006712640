.items-in-cart {
    width: 20px;
    height: 20px;
    background-color: tomato;
    border-radius: 15px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 2;
    left: 15px;
    top: 10px;
}
