.modal {
    position: fixed;

    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);

    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active-modal {
    opacity: 1;
    pointer-events: all;
    z-index: 100;
}

.modal__content {
    padding: 20px 94px;
    border-radius: 12px;
    background-color: #fff;
}
