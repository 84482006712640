body {
  margin: 0;
  font-family: "Formular", "Segoe UI", "Roboto", "Oxygen", "Ubuntu";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

:root {
  --blue: #3071b7;
  --dark-blue: #13446d;
  --dark: #070707;
  --light-blue: #e1e9fc;
}

@font-face {
  font-family: "Formular";
  src: url("./assets/fonts/Formular.woff") format("woff"), url("./assets/fonts/Formular-Medium.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Formular";
  src: url("./assets/fonts/Formular-Medium.woff") format("woff"),
    url("./assets/fonts/Formular-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  max-width: 1470px;
  margin: 0 auto;
  padding: 0;
  color: #13446d;
}

a {
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
