.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0 50px 0;
    margin-top: 80px;
    background-color: var(--dark-blue);
}

.footer__container {
    display: flex;
    padding: 0px 140px;
    margin: 0 auto;
    width: 100%;

    justify-content: space-between;
}

.footer__copyright {
    margin-top: 60px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: white;
    margin-bottom: 10px;
}

.logo__container {
    display: flex;
    max-height: 85px;
}

.logo {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.footer__list {
    display: flex;
    flex-direction: column;
    max-height: 160px;
    gap: 12px;
}

.footer__item {
    list-style: none;
    padding-top: 20px;
}

.footer__link {
    font-size: 14px;
    line-height: 17px;
    color: white;
    text-decoration: none;
}

.footer__link {
    display: flex;
    max-width: 185px;
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    line-height: 17px;
    color: white;
    text-decoration: none;
}

.footer__icon {
    padding-right: 15px;
}

@media (max-width: 1120px) {
    .footer__container {
        flex-direction: column;
    }

    .footer__container {
        padding: 0px 16px;
        width: auto;
        gap: 40px;
    }
}
