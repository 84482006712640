.nofish {
    position: absolute;
    background: white;
    z-index: 7777;
    padding: 75px;

    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;

    box-shadow: 0 0 20px #ccc;
    border-radius: 30px;
}
