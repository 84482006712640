.post__list {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;

    padding: 0px 140px;

    gap: 40px;

}

.post {
    position: relative;
    max-width: 360px;
    max-height: 500px;
    border-radius: 6px;
    list-style: none;
}

.post__img {
    width: 100%;
    height: 100%;
}

.post__description {
    position: absolute;
    bottom: 28px;
    left: 28px;
    display: flex;
    flex-direction: column;
}

.post__title {
    max-width: 230px;
    margin-bottom: 44px;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    color: white;
}

.post__button {
    padding: 21px 68px;
    min-width: 230px;
    border-radius: 10px;
    background-color: white;
    color: var(--dark-blue);
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}

@media (max-width: 1120px) {
    .post__list {
        padding: 0px 16px;
        justify-content: center;
        flex-wrap: wrap;
    }
}
