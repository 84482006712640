.category__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;

    padding: 0px 140px;

    gap: 40px;
}

.card {
    display: flex;
    align-items: center;
    min-height: 110px;
    box-shadow: 0 8px 24px rgba(198, 211, 241, 0.8);
    border-radius: 6px;
    text-decoration: none;
}

.card:hover {
    box-shadow: 0 20px 30px #c6d3f1;
    transition: 0.2s;
}

.card__img {
    max-width: 58px;
    max-height: 58px;
}

.card__title {
    margin-left: 20px;
    max-width: 140px;
    font-size: 14px;
    line-height: 100%;
    font-weight: 500;
    letter-spacing: 0.0275em;
    color: var(--dark-blue);
}

.img__container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    min-height: 110px;
    background-color: var(--light-blue);
    border-radius: 6px 0 0 6px;
}

@media (max-width: 1120px) {
    .category__list {
        padding: 0px 16px;
        justify-content: center;
    }
}
