.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo__container {
  display: flex;
  align-items: center;

  gap: 48px;
}

.logo {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.header__description {
  max-width: 250px;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.0275em;
  color: var(--dark-blue);
}

.info__container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header__phone {
  text-decoration: none;
  color: var(--dark-blue);
}

.link {
  max-width: 24px;
  max-height: 24px;
}

.icon {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: var(--dark-blue);
}

.icon:hover {
  stroke: var(--blue);
  transition: 0.2s;
}

@media (max-width: 1120px) {
  .header__description,
  .header__phone {
    display: none;
  }
}

/* form */

.form h3 {
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;

  color: #13446d;
  padding-bottom: 30px;
}

.form__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #070707;
  padding-bottom: 20px;
}

.form label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* #070707 */

  color: #070707;
}

.form__input-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}

.form input {
  padding: 16px;
  border: 1.5px solid #3071b7;
  border-radius: 6px;
}

.form button {
  width: 100%;
}
