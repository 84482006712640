.orders-content {
    width: 100%;
    display: flex;
}

.orders-content table {
    border-collapse: collapse;
    display: flex;
    width: 100%;
}

.orders-content tr {
    border: 1px solid black;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.orders-content td {
    padding: 10px;
}
