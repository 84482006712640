main {
    padding: 0 140px;
}

.path {
    padding: 80px 0 50px;
}

.catalog-wrapper {
    display: flex;
}

.catalog {
    width: 75%;
}

@media (max-width: 1120px) {
    .catalog {
        width: 100%;
    }
}
