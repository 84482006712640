.accordion-wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;

    width: 25%;

    margin-right: 20px;
    padding: 20px;
}

.accordionHeading .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordionHeading p {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.022em;

    color: #13446d;
}

.accordionContent {
    height: 0;
    opacity: 0;
    display: flex;
    overflow: hidden;
    transition: all 600ms ease-in-out;
}

.accordionContent.show {
    height: fit-content;
    opacity: 1;
}

.accordion__list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 20px;
}

.accordion__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.022em;

    color: #070707;
}

@media (max-width: 1120px) {
    .accordion-wrapper {
        display: none;
    }
}
