.header__navbar {
  display: flex;
  align-items: center;
  max-height: 60px;
  justify-content: space-between;
}

.nav__list {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 0;
  max-height: 44px;
}

.nav__list > :not(:last-child) {
  margin-right: 60px;
}

.nav__list > :last-child {
  margin-right: 55px;
}

.nav__item {
  display: flex;
  /* align-items: center; */
  list-style: none;
  max-height: 24px;
  flex-direction: column;
  position: relative;
}

.nav__link {
  display: flex;
  /* align-items: center; */
  text-decoration: none;
  color: var(--dark-blue);
}

.benefit__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 27px;
  max-height: 60px;
  border: 1px solid var(--dark-blue);
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
  font-family: "Formular";
  font-size: 20px;
  line-height: 24px;
}

.benefit__button:hover {
  background-color: var(--blue);
  color: white;
  transition: 0.2s;
}

.nav__sub-list {
  z-index: 100;
  display: none;
  position: absolute;
  top: 20px;
  flex-direction: column;
  gap: 5px;
  padding-top: 20px;
  list-style: none;
  width: 200px;
}

.nav__item:hover > .nav__sub-list {
  display: flex;
}

@media (max-width: 1120px) {
  .nav__list {
    display: none;
  }
}
