.cart-menu {
    position: absolute;
    top: 30px;
    right: 330px;
    background-color: white;
    box-shadow: 0 8px 24px rgb(198 211 241 / 80%);
    border-radius: 6px;
    cursor: pointer;
    width: 330px;
    padding: 10px 20px;
    z-index: 2;
}

.cart-menu__arrange {
    margin-top: 20px;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.cart-menu__total-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
