.about-main {
    padding: 0;
}

.container-about {
    max-width: 1222px;
    padding: 0 16px;
    margin: 0px auto;
}

.about-promo {
    background-image: url(../../assets/images/about-promo.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    height: 400px;
}

.about-promo h1 {
    padding: 54px;
    max-width: 465px;

    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;

    color: #ffffff;
}

.about-info {
    display: flex;
    gap: 50px;
    padding: 70px 0 50px 200px;
}

.about-info__text {
    width: 50%;

    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;

    color: #070707;
}

.about-info__numbers {
    width: 50%;

    display: flex;
    flex-direction: column;

    gap: 18px;
}

.about-info__numbers span {
    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 100px;
    line-height: 100%;

    color: #3071b7;
}

.about-info__numbers p {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 100%;

    color: #3071b7;
}

.about-info__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;
}

.participants {
    background: #e1e9fc;
    padding: 92px 0 86px;
}

.participants h2 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;

    color: #13446d;
}

.participants-content {
    display: flex;

    gap: 22px;

    padding-top: 70px;
}

.participants-info {
    display: flex;
    flex-direction: column;

    gap: 30px;
}

.participants-info p {
    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;

    color: #070707;
}

.button-about {
    background: #3071b7;
    border-radius: 10px;
    padding: 22px 70px;

    width: min-content;
}

.participants img {
    height: 100%;
}

.request {
    background: #3071b7;
    padding: 85px 0;
}

.request h2 {
    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;

    color: #ffffff;

    margin-bottom: 60px;
}

.request p {
    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;

    color: #ffffff;
}

.request__wrapper {
    display: flex;
    gap: 184px;
}

.request__info {
    width: 100%;
}

.request__form {
    width: 100%;
}

.request__form form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.request__input {
    background: transparent;
    border: none;
    padding: 8px;
    border-bottom: 1.5px solid #ffffff;
}

.request__input::placeholder {
    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;

    color: rgba(255, 255, 255, 0.6);
}

.button-request {
    display: flex;
    width: fit-content;
    background: #ffffff;
    border-radius: 10px;
    padding: 22px 40px;
    border: none;
    font-family: "Formular";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    color: #13446d;
}

.about-legal__wrapper {
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
    gap: 200px;
}

.about-legal h2 {
    width: 100%;

    font-family: "Formular";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    /* or 36px */

    /* #13446D */

    color: #13446d;
}

.about-legal ul {
    display: flex;
    flex-direction: column;

    gap: 20px;
    list-style: none;

    width: 100%;
}

.about-legal li {
    font-family: "Formular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;

    color: #070707;
}

@media (max-width: 1120px) {
    .about-promo h1 {
        padding: 16px;

        font-weight: 500;
        font-size: 30px;
        line-height: 100%;
    }

    .about-info {
        flex-direction: column;
        gap: 30px;
        padding: 16px;
    }

    .about-info__numbers,
    .about-info__text {
        width: 100%;
    }

    .participants-content {
        flex-direction: column-reverse;
    }

    .request__wrapper,
    .about-legal__wrapper {
        flex-direction: column;
        gap: 40px;
    }
}
