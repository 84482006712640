.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 32px auto;
    max-width: 1160px;
    max-height: 400px;
}

.carousel {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 6px;
}

.inner {
    display: flex;
    max-width: 1160px;
    max-height: 400px;
    transition: ease-in-out 1s;
}

.left__arrow {
    position: absolute;
    border: none;
    left: 16px;
    background: url("../../assets/images/LeftArrow.svg") no-repeat 50% 50%;
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
}

.right__arrow {
    position: absolute;
    border: none;
    right: 16px;
    background: url("../../assets/images/RightArrow.svg") no-repeat 50% 50%;
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
}

.indicators {
    position: absolute;
    width: 100%;
    top: 45%;
    z-index: 99;
}

.pagination {
    position: absolute;
    display: flex;
    top: 240px;
    left: 50%;
}

.slide__dot {
    min-width: 10px;
    min-height: 10px;
    border: none;
    border-radius: 10px;
    background: #e1e9fc;
    margin-right: 10px;
    padding: 0;
}

.active {
    background-color: #13446d;
}

.item__container {
    display: flex;
    position: relative;
    max-height: 400px;
    min-width: 100%;
    cursor: pointer;
}

.item__img {
    display: flex;
    width: 100%;
    min-height: 250px;
}

.item__text {
    position: absolute;
    max-width: 470px;
    color: white;
    top: 75px;
    left: 55px;
    font-weight: 500;
    font-size: 50px;
    line-height: 100%;
}

@media (max-width: 1120px) {
    .left__arrow,
    .right__arrow {
        display: none;
    }
    .pagination {
        display: none;
    }
    .item__text {
        font-size: 30px;
    }
}
